import React from "react";
import TableForm from "../../components/TableForm";
import Title from "../../components/Title";

export default function Reservations() {
  const columns = [
    { field: "NAME", name: "NOME" },
    { field: "CELLPHONE", name: "TELEFONE" },
  ];

  return (
    <>
      <Title name="E-book" />

      <TableForm url="/ebook/listar" columns={columns} />
    </>
  );
}
