import React from "react";
import { ContainerLoading } from "./style";
import ReactLoading from "react-loading";

export default function Loading() {
  return (
    <ContainerLoading>
      <ReactLoading type="bars" color="#fff" />
      <h2>Aguarde...</h2>
    </ContainerLoading>
  );
}
