export const formatMoney = (i) => {
  const value = i?.target?.value ? i.target.value : i;

  let v = value.replace(/\D/g, "");
  v = (v / 100).toFixed(2) + "";
  v = v.replace(".", ",");
  v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

  return v;
};
