import styled from "styled-components";

export const FormActions = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;

  button.btn-cancel {
    margin-right: 1rem;
  }
`;

export const Classes = styled.div`
  button {
    background-color: #2f3a8f;
    color: white;
    margin-top: 1rem;
  }
  button:hover {
    background-color: #2f3a8f;
    color: white;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
