import React from "react";
import { WrapperProfile } from "./style";
import { useSelector } from "react-redux";
import { Icon } from "@material-ui/core";

export default function Profile() {
  const userLogged = useSelector((state) => state.userLogged);
  return (
    <WrapperProfile>
      <div className="wrapper-info">
        {userLogged.photo && (
          <div
            className="imgUser"
            style={{ backgroundImage: `url(${userLogged.photo})` }}
          />
        )}

        {!userLogged.photo && (
          <div className="imgIcon">
            <Icon color="action">face</Icon>
          </div>
        )}

        <div className="nameUser">{userLogged.name}</div>
      </div>
    </WrapperProfile>
  );
}
