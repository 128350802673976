import styled from "styled-components";
import banner from "../../assets/images/posts-image2.png";

export const ContainerPosts = styled.div`
  .banner {
    background-image: url("${banner}");
    height: 250px;
    margin: 0 auto;
    background-size: cover;
  }
  .filter {
    margin: 0 auto 1rem auto;
    border: 1px solid #e6e6e6;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    .wrapperButtonGroup {
      display: flex;
      align-items: center;

      > span {
        margin-right: 0.8rem;
      }

      button {
        span {
          color: #e2daf3;
          &.actived {
            color: #fde237;
          }
        }
      }
    }

    .search {
      width: 50%;
      margin-left: 0.8rem;
    }
  }
  .MuiButton-containedPrimary {
    background-color: #2f3a8f;
    &:hover {
      background-color: #2f3a8f;
    }
  }
  .MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
    border-color: white;
  }
  .btn-add-post {
    button {
      margin-bottom: 2rem;
      background: #2f3a8f;
      color: white;
      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .btn-show-more {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    button {
      background: #2f3a8f;
      color: white;
    }
  }

  @media (min-width: 320px) and (max-width: 600px) {
    .MuiPaper-root {
      padding: 0.5rem !important;
    }
    .banner {
      display: none;
    }

    .filter {
      flex-direction: column;
    }

    .wrapperButtonGroup {
      margin-bottom: 1rem;
    }

    .search {
      margin-left: 0 !important;
      width: 100% !important;
    }

    .type-post {
      flex-direction: column;
    }

    .title-post {
      width: 100%;
      strong {
        font-size: 17px !important;
      }
    }

    .type-post-left {
      width: 100% !important;

      svg {
        display: none;
      }
    }

    .type-post-right {
      width: 100% !important;
      margin-top: 1rem;
      align-items: center !important;

      .wrapper-count-answers {
        align-items: center !important;
        flex-direction: column !important;

        > svg {
          margin-top: 1rem;
        }
      }
    }

    .wrapperButtonGroup > span {
      display: none;
    }

    .data-post {
      flex-direction: column;
    }

    .author-post {
      justify-content: center !important;
    }

    .classes-list {
      flex-wrap: wrap;
      display: flex;

      span {
        margin-bottom: 0.5rem;
      }
    }
  }
`;

export const ListPosts = styled.div`
  .posts:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const Counter = styled.div`
  margin: 1rem auto;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #2f3a8f;
  span {
    margin-left: 0.5rem;
  }
`;

export const HeaderPost = styled.div`
  color: #444;
  .author-post {
    display: flex;
    padding: 0.8rem;
    align-items: center;
    justify-content: space-between;
    .imgUser {
      width: 100px;
      height: 100px;
      background-size: cover;
      border-radius: 50%;
      border: 6px solid white;
    }
    .imgIcon {
      margin-right: 0.8rem;
      width: 50px;
      height: 50px;
      background: #f8f8f8;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 2rem;
        color: rgba(0, 0, 0, 0.54);
      }
    }
    .date-created {
      margin-left: 0.8rem;
    }

    .data-post {
      display: flex;
      align-items: center;
    }

    .actions-author {
      display: flex;
      align-items: center;

      button {
        background-color: white;
        border: 1px solid #2f3a8f;
        padding: 8px;
        &:not(:last-child) {
          margin-right: 1rem;
        }
        svg {
          color: #2f3a8f;
          font-size: 18px;
        }
      }
    }
  }

  .type-post {
    padding: 0.8rem;
    display: flex;
    .type-post-left {
      width: 70%;
      padding-right: 1rem;
      display: flex;
      svg {
        font-size: 2.5rem;
        margin-right: 0.8rem;
      }
      .title-post {
        display: flex;
        flex-direction: column;
        span {
          font-size: 18px;
        }
        strong {
          font-size: 21px;
          text-transform: uppercase;
        }
      }
    }

    .type-post-right {
      width: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      button {
        background: #2f3a8f;
        margin-top: 10px;
        display: flex;
        align-items: center;
        color: white;
        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          color: white;
        }
      }

      .titleAnswers {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
      }

      .totalAnswers {
        background: white;
        color: #2f3a8f;
        padding: 0px 8px;
        border-radius: 20px;
        font-weight: bold;
      }

      .date {
        font-size: 15px;
        strong {
          margin-left: 0.2rem;
        }
      }

      .wrapper-count-answers {
        display: flex;
        align-items: flex-end;
        > svg {
          margin-right: 1rem;
          font-size: 35px;
          color: white;
          border-radius: 50px;
          padding: 4px;
          background: #ffc107;
        }
      }

      .iconPeople,
      .iconList {
        margin-right: 0.8rem;
      }
    }

    &.activity {
      background-color: #2f3a8f38;
      color: #2f3a8f;
      display: flex;
      justify-content: space-between;
    }

    &.normal {
      background-color: #04d7c4;
      color: white;
    }
  }
`;

export const ContentPost = styled.div`
  padding: 0.8rem;
  color: #444;
  p {
    font-size: 16px;
  }

  img {
    max-width: 100%;
  }
  .classes-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    span {
      background-color: #ffc107;
      color: white;
      padding: 0.3rem 0.8rem;
      border-radius: 10px;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 0.5rem;
      &:not(:last-child) {
        margin-right: 0.8rem;
      }
    }
  }
`;

export const Post = styled.div`
  margin: 0 auto;
  padding: 0.5rem;
  border-radius: 3px;

  &.activity {
    background-color: #2f3a8f38;
  }
  &.normal {
    background-color: #04d7c4;
  }
`;

export const AttachmentPost = styled.div`
  padding: 0.8rem;
  svg {
    margin-right: 0.5rem;
  }
  .counter {
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #444;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 0.8rem;
    span {
      font-size: 21px;
    }
  }

  .list-attachments {
    margin-top: 0.8rem;
    a {
      display: flex;
      align-items: center;
      color: #444;
      text-decoration: none;
      font-size: 16px;
      &:hover {
        color: #2f3a8f;
      }
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      svg {
        font-size: 18px;
      }
    }
  }
`;
