import React, { useState, useCallback } from "react";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel, Warning } from "@material-ui/icons";
import { ContainerModalQrCode } from "./style";
import { useEffect } from "react";
import { toast } from "react-toastify";
import API from "../../utils/api";
import { Skeleton } from "@material-ui/lab";

export default function ContentQrCodeModal({ closeModal }) {
  const [qrCode, setQrCode] = useState(null);
  const [loadingQrCode, setLoadingQrCode] = useState(false);

  const getQrcodeZapi = useCallback(async () => {
    setLoadingQrCode(true);
    try {
      const res = await API.get("/notificar-alunos/qrcode");

      if (res?.data?.value) {
        setQrCode(res.data.value);
        setLoadingQrCode(false);
      }

      if (res?.data?.connected) {
        toast.warning("Instância já conectada.");
        setLoadingQrCode(null);
        setTimeout(() => {
          closeModal();
        }, 1000);
      }
    } catch (error) {
      toast.error(
        error?.data?.error ? `${error.data.error}` : "Ocorreu um erro."
      );
      setLoadingQrCode(null);
    }
  }, [closeModal]);

  useEffect(() => {
    getQrcodeZapi();

    const intervalId = setInterval(() => {
      getQrcodeZapi();
    }, 20000);

    return () => clearInterval(intervalId);
  }, [getQrcodeZapi]);

  return (
    <ContainerModalQrCode>
      <DialogTitle>
        Leia o QRCode
        <IconButton component="span" onClick={closeModal}>
          <Tooltip title="Fechar">
            <Cancel />
          </Tooltip>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {loadingQrCode === false && <img src={qrCode} alt="QRCode" />}

        {loadingQrCode === true && (
          <div className="wrapper-skeleton-qrcode">
            <Skeleton variant="rect" width={256} height={246} />
          </div>
        )}

        {loadingQrCode === null && (
          <div className="no-qrcode">
            <Warning />
            <p>Sem QRCode</p>
          </div>
        )}
      </DialogContent>
    </ContainerModalQrCode>
  );
}
