export const valueNotEmpty = (value, control, name) => {
  if (!control.formState.touched[name]) {
    return true;
  }

  if (value === "") {
    return true;
  }

  if (value.trim().length > 0) {
    return true;
  }

  return false;
};
