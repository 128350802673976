import React from "react";
import { toast } from "react-toastify";
import TableForm from "../../components/TableForm";
import Title from "../../components/Title";
import API from "../../utils/api";

export default function Intensive() {
  const columns = [
    { field: "STUDENT", name: "ALUNO", type: "status" },
    { field: "NAME", name: "NOME" },
    { field: "CPF", name: "CPF" },
    { field: "CELLPHONE", name: "TELEFONE" },
  ];

  const moreActions = [
    {
      title: "Novo aluno",
      icon: "person",
      color: "rgba(0,0,0,0.54)",
      onClick: (row) => newUser(row),
      refreshDataAfterOnClick: true,
    },
  ];

  const newUser = async (user) => {
    const loadingToast = toast.loading("Criando usuário na plataforma...");
    try {
      const res = await API.post(`/intensivao/criar-aluno`, user);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  return (
    <>
      <Title name="Contatos - Intensivão de Redação" />

      <TableForm
        url="/intensivao/listar-inscritos"
        columns={columns}
        moreActions={moreActions}
      />
    </>
  );
}
