import styled from "styled-components";

export const TextAreaContainer = styled.div`
  textarea {
    padding: 18.5px 14px;
    border: 1px solid #c4c4c4;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    border-radius: 4px;
    resize: none;
    min-height: 136px;
    &:focus {
      outline: auto 1px #3f51b5;
    }
    &.error {
      border: 1px solid red;
    }
  }

  textarea::-webkit-input-placeholder {
    color: #757575;
  }

  textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #757575;
  }

  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #757575;
  }

  textarea:-ms-input-placeholder {
    color: #757575;
  }

  textarea::placeholder {
    color: #757575;
  }
`;
