import styled from "styled-components";

export const TitlePost = styled.div`
  display: flex;
  margin-bottom: 2rem;
  color: black;

  .title {
    display: flex;
    flex-direction: column;
    span {
      font-size: 18px;
    }
    h2 {
      margin: 0;
    }
  }
  svg {
    font-size: 2.5rem;
    margin-right: 0.8rem;
  }
`;
