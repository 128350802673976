import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { formatMoney } from "../../utils/format/formatMoney";

export default function InputCurrency({
  name,
  label,
  control,
  defaultValue,
  rules,
  errors,
  variant,
}) {
  return (
    <Controller
      render={({ onChange, ...field }) => (
        <TextField
          {...field}
          variant={variant ? variant : "outlined"}
          fullWidth
          onChange={(value) => onChange(formatMoney(value))}
          autoComplete="off"
          error={errors[name] ? true : false}
          required={rules && rules.required ? rules.required : false}
          label={label}
          helperText={
            errors[name] && errors[name].type === "required"
              ? "Campo obrigatório"
              : ""
          }
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        ></TextField>
      )}
      rules={{
        ...rules,
      }}
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}
