import { Button, Icon } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../store/Loading/actions";
import { FormMyProfilePhotoContainer, Photo } from "./style";
import API from "../../utils/api";
import { setUser } from "../../store/UserLogged/actions";
import { toast } from "react-toastify";

export default function FormMyProfilePhoto() {
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state.userLogged);
  const [previewImg, setPreviewImg] = useState(null);
  const [photo, setPhoto] = useState(null);

  const onChangePhoto = (file) => {
    setPhoto(file);
    const url = URL.createObjectURL(file);
    setPreviewImg(url);
  };

  const savePhoto = async () => {
    if (photo && previewImg) {
      try {
        dispatch(showLoading());

        let formData = new FormData();
        formData.append("photo", photo);

        const res = await API.put(
          `/usuarios/alterar-foto/${userLogged.id_user}`,
          formData
        );

        dispatch(setUser({ ...userLogged, photo: res.data.photo }));

        toast.success(`${res.data.message}`);

        setPreviewImg(null);
        setPhoto(null);
        document.getElementById("photo").value = "";
      } catch (error) {
        toast.error(
          error && error.data && error.data.error
            ? `${error.data.error}`
            : "Ocorreu um erro."
        );
      } finally {
        dispatch(hideLoading());
      }
    }
  };

  return (
    <FormMyProfilePhotoContainer>
      <Photo>
        {!userLogged.photo && !previewImg && (
          <div className="imgIcon">
            <Icon color="action">face</Icon>
          </div>
        )}

        {previewImg && (
          <div
            className="imgUser"
            style={{
              backgroundImage: `url(${previewImg})`,
            }}
          />
        )}

        {userLogged.photo && !previewImg && (
          <div
            className="imgUser"
            style={{
              backgroundImage: `url(${userLogged.photo})`,
            }}
          />
        )}
      </Photo>

      <input
        type="file"
        onChange={(e) => onChangePhoto(e.target.files[0])}
        accept="image/*"
        id="photo"
        name="photo"
      />

      <div className="btn-send-photo">
        <Button startIcon={<Icon>photo_camera</Icon>} onClick={savePhoto}>
          Enviar foto
        </Button>
      </div>
    </FormMyProfilePhotoContainer>
  );
}
