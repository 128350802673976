import styled from "styled-components";

export const ContainerModalQrCode = styled.div`
  .MuiDialogTitle-root {
    background: #2f3a8f;

    h2 {
      width: 100%;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        color: white;
      }
    }
  }

  .MuiDialogContent-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper-skeleton-qrcode {
    padding: 15px 0;
  }

  .no-qrcode {
    width: 256px;
    height: 246px;
    background: #e6e6e6;
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg {
      font-size: 50px;
      color: #2f3a8f;
    }
  }
`;
