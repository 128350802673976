import React from "react";
import TableForm from "../../components/TableForm";
import Title from "../../components/Title";
import API from "../../utils/api";
import { toast } from "react-toastify";

export default function Reservations() {
  const columns = [
    { field: "NAME", name: "NOME" },
    { field: "CELLPHONE", name: "TELEFONE" },
    { field: "PLAN", name: "PLANO DESEJADO" },
    { field: "TAGS", name: "TAGS" },
  ];

  const moreActions = [
    {
      title: "Pagar matrícula",
      icon: "monetization_on",
      color: "#8FBC8F",
      onClick: (row) => payRegistration(row),
      refreshDataAfterOnClick: true,
    },
    {
      title: "Novo aluno",
      icon: "accessibility_new",
      color: "rgba(0,0,0,0.54)",
      onClick: (row) => markAsNewStudent(row),
      refreshDataAfterOnClick: true,
    },
    {
      title: "Confirmar aluno",
      icon: "check_box",
      color: "green",
      onClick: (row) => markAsConfirmed(row),
      refreshDataAfterOnClick: true,
    },
    {
      title: "Excluir",
      icon: "delete",
      color: "red",
      onClick: (row) => deleteReservation(row),
      refreshDataAfterOnClick: true,
    },
  ];

  const payRegistration = async (data) => {
    try {
      const res = await API.put(`/reservas/pagar-matricula/${data.ID}`);
      toast.success(`${res.data.message}`);
    } catch (error) {
      console.log(error);
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  const markAsNewStudent = async (data) => {
    try {
      const res = await API.put(`/reservas/novo-aluno/${data.ID}`);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  const markAsConfirmed = async (data) => {
    try {
      const res = await API.put(`/reservas/confirmar-aluno/${data.ID}`);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  const deleteReservation = async (data) => {
    try {
      const res = await API.delete(`/reservas/deletar/${data.ID}`);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    }
  };

  return (
    <>
      <Title name="Reservas 2025" />

      <TableForm
        url="/reservas/listar"
        columns={columns}
        moreActions={moreActions}
        moreActionsMenu
      />
    </>
  );
}
