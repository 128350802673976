import React, { useCallback, useEffect, useState } from "react";
import {
  AppBar,
  Button,
  DialogContent,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import InputText from "../InputText";
import InputTime from "../InputTime";
import { HighlightOff } from "@material-ui/icons";
import { toast } from "react-toastify";
import { Attachments, AnswerContainer, FormActions } from "./style";
import moment from "moment";
import ButtonCancel from "../ButtonCancel";
import ButtonSave from "../ButtonSave";
import TextArea from "../TextArea";
import API from "../../utils/api";
import { useDispatch } from "react-redux";
import { hideLoading, showLoading } from "../../store/Loading/actions";
import { timer } from "../../validators/timer";

export default function ContentAnswerModal({ post, closeModal }) {
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState({});
  const [showSave, setShowSave] = useState(false);

  const { control, handleSubmit, errors, setValue } = useForm({
    mode: "all",
  });

  const getDataAnswer = useCallback(async () => {
    dispatch(showLoading());
    try {
      const res = await API.get(`/posts/resposta/${post.ID}`);
      if (res.data) {
        setValue("theme", res.data.THEME);
        setValue("type_composing", res.data.TYPE_COMPOSING);
        setValue("timer", res.data.TIMER);
        setValue("observation_student", res.data.OBSERVATION_STUDENT);
        setAnswer(res.data);
      }
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  }, [dispatch, post.ID, setValue]);

  useEffect(() => {
    getDataAnswer();
  }, [getDataAnswer]);

  const onSubmit = (data) => {
    if (answer && (answer.LINK || answer.FILE)) {
      dispatch(showLoading());
      let formData = new FormData();
      if (answer.LINK) {
        data.link = answer.LINK;
      }
      formData.append("file", answer.FILE);
      formData.append("data", JSON.stringify(data));

      if (!answer.hasOwnProperty("ID") || answer.ID === null) {
        create(formData);
      } else {
        update(formData);
      }
    } else {
      toast.error(`Adicione um anexo na resposta.`);
    }
  };

  const create = async (formData) => {
    try {
      const res = await API.post(
        `/posts/adicionar-resposta/${post.ID}`,
        formData
      );
      toast.success(`${res.data.message}`);
      closeModal(true);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const update = async (formData) => {
    try {
      const res = await API.put(
        `/posts/atualizar-resposta/${post.ID}`,
        formData
      );
      toast.success(`${res.data.message}`);
      closeModal(true);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const onChangeFile = (file) => {
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png"];
      if (file.size < 10485760 && validImageTypes.includes(file.type)) {
        if (answer && (answer.FILE || answer.LINK)) {
          toast.error(`Já existe um anexo na atividade.`);
        } else {
          setAnswer({ ...answer, FILE: file });
        }
      } else {
        toast.error(`Arquivo não suportado.`);
      }
    }
  };

  const deleteFile = () => {
    setAnswer({
      ID: answer.ID ? answer.ID : null,
      THEME: answer.THEME,
      TYPE_COMPOSING: answer.TYPE_COMPOSING,
      TIMER: answer.TIMER,
      OBSERVATION_STUDENT: answer.OBSERVATION_STUDENT,
    });

    document.getElementById("upload").value = "";
  };

  useEffect(() => {
    let datePost = moment(post.LIMIT_DATE).format("MM/DD/YYYY HH:mm:ss");

    let newDate = new Date();

    newDate = moment(newDate).format("MM/DD/YYYY HH:mm:ss");

    if (newDate <= datePost) {
      setShowSave(true);
    }
  }, [post.LIMIT_DATE]);

  return (
    <AnswerContainer>
      <AppBar>
        <Toolbar>
          <Typography variant="h6">
            Resposta: {post.TITLE.toUpperCase()}
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12} sm={6}>
              <InputText
                name="theme"
                label="Tema"
                control={control}
                rules={{ required: true }}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputText
                name="type_composing"
                label="Grade"
                control={control}
                rules={{ required: true }}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <InputTime
                name="timer"
                label="Tempo Gasto"
                control={control}
                rules={{ required: true, validate: (value) => timer(value) }}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12}>
              <TextArea
                name="observation_student"
                placeholder="Observação do(a) Aluno(a)"
                control={control}
                rules={{ required: false }}
                errors={errors}
              ></TextArea>
            </Grid>
          </Grid>
          <Attachments>
            <h2>
              Anexo: <span>(.jpg, .jpeg, .png)</span>
            </h2>
            <h4>
              (Verifique, por favor, se a imagem está nítida e se está na
              vertical. Certifique-se, também, que não está de cabeça para
              baixo.)
            </h4>
            <input
              onChange={(e) => onChangeFile(e.target.files[0])}
              accept="image/*"
              id="upload"
              name="upload"
              type="file"
            />
            <label htmlFor="upload">
              <Button variant="contained" color="primary" component="span">
                Adicionar anexo
              </Button>
            </label>
            {answer.LINK && (
              <ul>
                <li>
                  <HighlightOff onClick={deleteFile} />

                  <a rel="noreferrer" target="_blank" href={answer.LINK}>
                    {answer.LINK.split("/")[answer.LINK.split("/").length - 1]}
                  </a>
                </li>
              </ul>
            )}
            {answer.FILE && (
              <ul>
                <li>
                  <HighlightOff onClick={deleteFile} />

                  {answer.FILE.name}
                </li>
              </ul>
            )}
          </Attachments>
          <FormActions>
            <ButtonCancel click={() => closeModal()} />
            {showSave && <ButtonSave />}
          </FormActions>
        </DialogContent>
      </form>
    </AnswerContainer>
  );
}
