const drawerWidth = 240;

const style = (theme) => ({
  root: {
    display: "flex",
    minHeight: "100%",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "#FFF",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: "0.1s",
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width"], {
      easing: theme.transitions.easing.sharp,
      duration: "0.1s",
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  iconLink: {
    minWidth: 35,
    color: "#e2daf3",
  },
  textMenu: {
    color: "#e2daf3",
  },
  linkMenu: {
    display: "flex",
    textDecoration: "none",
    color: "inherit",
    width: "100%",
    alignItems: "center",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    boxShadow: "0 0.5rem 1rem 0 rgba(0, 0, 0, 0.25)",
    borderRight: 0,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "0.1s",
    }),
  },
  drawerClose: {
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.25)",
    borderRight: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: "0.1s",
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  logo: {
    maxWidth: "100%",
    width: 145,
    marginTop: -6,
  },
  wrapperLogo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  list: {
    height: "100%",
    backgroundColor: "#2f3a8f",
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
  },
  wrapperToolbarSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  wrapperToolbarEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
  wrapperToolbar: {
    boxShadow: "0 4px 2px -2px rgba(0, 0, 0, 0.25)",
  },
  hiddenText: {
    display: "none",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  mainContent: {
    width: "100%",
    padding: "15px",
    marginTop: "64px",
  },
  mainContentMobile: {
    width: "calc(100% - 57px)",
    padding: "15px",
    marginTop: "64px",
  },
});

export default style;
