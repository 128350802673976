import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import API from "../../utils/api";
import { showLoading, hideLoading } from "../../store/Loading/actions";
import TableAnswers from "../../components/TableAnswers";
import { TitlePost } from "./style";
import { BorderColor } from "@material-ui/icons";
import { useParams } from "react-router-dom";

export default function Answers() {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const { id } = useParams();

  const getAllAnswersByPost = useCallback(async () => {
    dispatch(showLoading());
    try {
      const res = await API.get(`/posts/todas-respostas/post/${id}`);
      setData(res.data);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  }, [id, dispatch]);

  useEffect(() => {
    getAllAnswersByPost();
  }, [getAllAnswersByPost]);

  return (
    <>
      <TitlePost>
        <BorderColor />
        <div className="title">
          <span>Atividade</span>
          <h2>{data[0]?.TITLE.toUpperCase()}</h2>
        </div>
      </TitlePost>

      <TableAnswers data={data} getAllAnswersByPost={getAllAnswersByPost} />
    </>
  );
}
