export default function loading(state = false, action) {
  switch (action.type) {
    case "SHOW_LOADING":
      state = action.loading;
      return state;

    case "HIDE_LOADING":
      state = action.loading;
      return state;

    default:
      return state;
  }
}
