import styled from "styled-components";

export const FormMyProfileDataContainer = styled.div`
  form {
    .actions {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      .change-password {
        background: rgba(0, 0, 0, 0.54);
        color: white;
      }
    }
  }
`;
