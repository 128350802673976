import React from "react";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { cpfIsValid } from "../../validators/cpf";
import InputMask from "react-input-mask";

export default function InputCpf({
  control,
  defaultValue,
  rules,
  errors,
  variant,
}) {
  return (
    <Controller
      as={
        <InputMask
          mask="999.999.999-99"
          value={
            control.getValues(`cpf`) ? control.getValues(`cpf`) : defaultValue
          }
          disabled={false}
          maskChar="_"
        >
          {() => (
            <TextField
              variant={variant ? variant : "outlined"}
              fullWidth
              autoComplete="off"
              required={rules && rules.required ? rules.required : false}
              error={errors.cpf ? true : false}
              label="CPF"
              helperText={
                errors.cpf && errors.cpf.type === "required"
                  ? "Campo obrigatório"
                  : errors.cpf && errors.cpf.type === "validate"
                  ? "CPF Inválido"
                  : ""
              }
              type="text"
            ></TextField>
          )}
        </InputMask>
      }
      rules={{ ...rules, validate: cpfIsValid }}
      control={control}
      name="cpf"
      defaultValue={defaultValue ? defaultValue : ""}
    />
  );
}
