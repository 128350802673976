import styled from "styled-components";

export const AnswersContainer = styled.div`
  .itemClass:not(:last-child) {
    margin-bottom: 4rem;
  }
`;

export const TableFormContainer = styled.div`
  .MuiTableRow-head {
    background-color: #2f3a8f;
  }

  .MuiTableCell-head {
    color: #e2daf3;
  }

  .MuiTableContainer-root {
    border-top: none !important;
  }

  .titleClass {
    padding: 0;
    border: 0;
  }
`;

export const TitleClass = styled.div`
  background: #ffc107;
  padding: 1rem;
  color: white;
  display: flex;
  align-items: center;
  h2 {
    margin: 0;
    font-weight: bold;
  }
  svg {
    margin-right: 0.5rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  svg {
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
    &.correct {
      margin-right: 1rem;
    }
  }
`;

export const NotFoundData = styled.div`
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #757575;
  font-size: 20px;
  font-weigth: bold;
  flex-direction: column;
  .icon {
    font-size: 35px;
    margin-bottom: 0.4rem;
  }
`;
