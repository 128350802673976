import { debounce, Grid, LinearProgress, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ButtonCancel from "../../components/ButtonCancel";
import ButtonSave from "../../components/ButtonSave";
import InputText from "../../components/InputText";
import TableForm from "../../components/TableForm";
import Title from "../../components/Title";
import { hideLoading, showLoading } from "../../store/Loading/actions";
import { FormActions, ListUsers } from "./style";
import { toast } from "react-toastify";
import API from "../../utils/api";
import { DeleteForever, Redo } from "@material-ui/icons";

export default function Classes() {
  const dispatch = useDispatch();
  const { control, handleSubmit, errors, reset } = useForm({
    mode: "all",
  });

  const [viewTable, setViewTable] = useState(true);
  const [classItem, setClass] = useState({});
  const [students, setStudents] = useState([]);
  const [associatedStudents, setAssociatedStudents] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const columns = [{ field: "NAME", name: "NOME" }];

  const onSubmit = (data) => {
    dispatch(showLoading());
    if (!classItem.hasOwnProperty("ID")) {
      create(data);
    } else {
      update(data);
    }
  };

  const create = async (data) => {
    try {
      const res = await API.post("/turmas", data);
      setViewTable(true);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const update = async (data) => {
    try {
      const res = await API.put(`/turmas/${classItem.ID}`, data);
      setViewTable(true);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const deleteClass = async (data) => {
    try {
      const res = await API.delete(`/turmas/${data.ID}`);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const editClass = (item) => {
    reset();
    setClass(item);
    setStudents([]);
    getStudentByClass(item.ID);
    setViewTable(false);
  };

  const addClass = () => {
    reset();
    setClass({
      NAME: "",
    });
    setViewTable(false);
  };

  const cancelAddClass = () => {
    setViewTable(true);
  };

  const onFilter = debounce((text) => {
    if (text.length > 2) {
      getStudentByName(text);
    }
  }, 700);

  const getStudentByName = async (name) => {
    setLoadingSearch(true);

    try {
      const res = await API.get(`/usuarios/alunos/${name}`);
      setStudents(res.data.data);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      setLoadingSearch(false);
    }
  };

  const getStudentByClass = async (id) => {
    dispatch(showLoading());
    try {
      const res = await API.get(`/usuarios/alunos/turma/${id}`);
      setAssociatedStudents(res.data.data);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const associateStudentInClass = async (id) => {
    dispatch(showLoading());
    const data = {
      id_user: id,
      id_class: classItem.ID,
    };
    try {
      const res = await API.post(`/turmas/associar-aluno`, data);
      await getStudentByClass(classItem.ID);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const disassociateStudentInClass = async (id) => {
    dispatch(showLoading());
    try {
      const res = await API.delete(`/turmas/desassociar-aluno/${id}`);
      await getStudentByClass(classItem.ID);
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      <Title name="Turmas" />

      {viewTable && (
        <TableForm
          url="/turmas"
          columns={columns}
          add={addClass}
          edit={(item) => {
            editClass(item);
          }}
          deleteItem={(item) => {
            return new Promise((resolve) => {
              deleteClass(item);
              resolve();
            });
          }}
        />
      )}

      {!viewTable && (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs>
              <InputText
                name="name"
                label="Nome"
                control={control}
                defaultValue={classItem.NAME}
                rules={{ required: true }}
                errors={errors}
              />
            </Grid>
          </Grid>

          {classItem.ID && (
            <ListUsers>
              <div className="search">
                <h3>Resultados</h3>
                <TextField
                  size="small"
                  fullWidth
                  variant="outlined"
                  label="Buscar alunos"
                  onChange={(e) => onFilter(e.target.value)}
                />

                <ul>
                  {loadingSearch && <LinearProgress />}
                  {students.map((student) => {
                    return (
                      <li key={student.ID}>
                        <span>{student.NAME}</span>
                        <Redo
                          onClick={() => associateStudentInClass(student.ID)}
                        />
                      </li>
                    );
                  })}

                  {students.length === 0 && <li>Aluno não encontrado</li>}
                </ul>
              </div>
              <div className="associatedUsers">
                <h3>Alunos</h3>
                <ul>
                  {associatedStudents.map((associatedStudent) => {
                    return (
                      <li key={associatedStudent.ID}>
                        <span>{associatedStudent.NAME}</span>
                        <DeleteForever
                          onClick={() =>
                            disassociateStudentInClass(
                              associatedStudent.ID_USER
                            )
                          }
                        />
                      </li>
                    );
                  })}

                  {associatedStudents.length === 0 && (
                    <li>Nenhum aluno vinculado a turma</li>
                  )}
                </ul>
              </div>
            </ListUsers>
          )}

          <FormActions>
            <ButtonCancel click={cancelAddClass} />
            <ButtonSave />
          </FormActions>
        </form>
      )}
    </>
  );
}
