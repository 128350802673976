import React, { useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import jwt_decode from "jwt-decode";
import store from "./store";
import { setUser } from "./store/UserLogged/actions";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./utils/theme";
import Loading from "./components/Loading";
import { ToastContainer } from "react-toastify";

function CustomLoading() {
  const loading = useSelector((state) => state.loading);
  return loading && <Loading />;
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const setUserData = () => {
      const token = localStorage.getItem("token");

      try {
        if (token) {
          const decoded = jwt_decode(token);
          dispatch(setUser(decoded));
        }
      } catch (error) {}
    };

    setUserData();
  }, [dispatch]);

  return (
    <>
      <ToastContainer theme="colored" />
      <CustomLoading />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </>
  );
}

function AppWrapper() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  );
}

export default AppWrapper;
