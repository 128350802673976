import React from "react";
import Title from "../../components/Title";
import { ContainerHome } from "./style";

export default function Home() {
  return (
    <ContainerHome>
      <Title name="Olá! Seja bem-vindo (a) ao Ligando Ideias!" />

      <p>
        Eu sou a Lays e serei a sua professora de Redação. É um prazer enorme
        tê-lo (la) aqui comigo no nosso cantinho reservado para te orientar, da
        melhor maneira possível, o qual tem como objetivo aperfeiçoar sua
        produção textual. Nesse espaço, você terá acesso a conteúdos sobre
        Redação, que vão desde conhecimento sobre atualidades até toda a parte
        estrutural do gênero textual. Além disso, você conseguirá postar suas
        redações e recebê-las de volta corrigidas. Essa correção será
        completíssima, pois você receberá sua nota, bem como o parecer da
        corretora bem detalhado. Demais né? Tenho certeza de que você irá
        adquirir muitos conhecimentos que te farão um (a) excelente candidato
        (a) para a vaga tão sonhada além de te fazer um (a) cidadão (cidadã) com
        senso crítico apurado. E aí, está preparado (a)? Vamos juntos em busca
        do início do seu sucesso. Um beijo enorme e bons estudos!
      </p>
    </ContainerHome>
  );
}
