import { Grid, MenuItem } from "@material-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "../../store/Loading/actions";
import API from "../../utils/api";
import ButtonCancel from "../ButtonCancel";
import ButtonSave from "../ButtonSave";
import InputText from "../InputText";
import SelectOption from "../Select";
import { FormActions } from "./style";

export default function FormSchedule({ closeForm, schedule }) {
  const dispatch = useDispatch();

  const { control, handleSubmit, errors } = useForm({
    mode: "all",
    defaultValues: {
      day: schedule?.DAY ? schedule.DAY : "",
      description_time: schedule?.DESCRIPTION_TIME
        ? schedule.DESCRIPTION_TIME
        : "",
      description_student: schedule?.DESCRIPTION_STUDENT
        ? schedule.DESCRIPTION_STUDENT
        : "",
    },
  });

  const onSubmit = (data) => {
    if (schedule?.ID) {
      updateSchedule(data);
    } else {
      createSchedule(data);
    }
  };

  const createSchedule = async (data) => {
    dispatch(showLoading());
    try {
      const res = await API.post("/horario", data);
      closeForm();
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const updateSchedule = async (data) => {
    dispatch(showLoading());
    try {
      const res = await API.put(`/horario/${schedule?.ID}`, data);
      closeForm();
      toast.success(`${res.data.message}`);
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} direction="row">
          <Grid item xs={12} sm={4}>
            <SelectOption
              name="day"
              label="Dia da semana"
              control={control}
              rules={{ required: true }}
              errors={errors}
            >
              <MenuItem value={1}>Segunda-feira</MenuItem>
              <MenuItem value={2}>Terça-feira</MenuItem>
              <MenuItem value={3}>Quarta-feira</MenuItem>
              <MenuItem value={4}>Quinta-feira</MenuItem>
              <MenuItem value={5}>Sexta-feira</MenuItem>
              <MenuItem value={6}>Sábado</MenuItem>
            </SelectOption>
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputText
              name="description_time"
              placeholder="00h00 às 00h00"
              label="Horário"
              control={control}
              rules={{ required: true }}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <InputText
              name="description_student"
              label="Descrição"
              control={control}
              rules={{ required: true }}
              errors={errors}
            />
          </Grid>
        </Grid>

        <FormActions>
          <ButtonCancel click={closeForm} />
          <ButtonSave />
        </FormActions>
      </form>
    </>
  );
}
