import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import API from "../../utils/api";
import { setUser } from "../../store/UserLogged/actions";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import {
  ContainerLogin,
  Logo,
  CardLogin,
  CardContentLogin,
  WrapperCardLogin,
  FooterForm,
} from "./style";
import fullLogo from "../../assets/images/new-logo.png";
import jwt_decode from "jwt-decode";
import AlertError from "../../components/AlertError";
import { theme } from "../../utils/theme";
import InputCpf from "../../components/InputCpf";
import InputPassword from "../../components/InputPassword";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";

export default function Login() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [currentYear, setCurrentYear] = useState(null);

  const { control, handleSubmit, errors } = useForm({ mode: "all" });

  const onSubmit = (data) => {
    data.cpf = data.cpf.replace(/[\s.-]*/gim, "");
    login(data);
  };

  const setUserData = () => {
    const token = localStorage.getItem("token");
    try {
      if (token) {
        const decoded = jwt_decode(token);
        dispatch(setUser(decoded));
      }
    } catch (error) {}
  };

  const login = async (data) => {
    setLoading(true);
    try {
      const res = await API.post("/auth/login", data);
      localStorage.setItem("token", res.data.token);
      setUserData();
    } catch (error) {
      setAlert(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCurrentYear();
  }, []);

  const getCurrentYear = () => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  };

  return (
    <>
      {localStorage.getItem("token") && <Redirect to="/inicio" />}
      <ContainerLogin>
        <WrapperCardLogin
          style={{ backgroundColor: isMobile ? "#2f3a8f" : "#00000054" }}
        >
          <CardLogin>
            <CardContentLogin>
              <Logo>
                <img src={fullLogo} alt="Ligando Ideias" />
              </Logo>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="center"
                >
                  <Grid item xs={12}>
                    <InputCpf
                      control={control}
                      rules={{ required: true }}
                      errors={errors}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputPassword
                      name="password"
                      label="Senha"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      errors={errors}
                    />
                  </Grid>
                </Grid>

                <FooterForm>
                  {alert && (
                    <AlertError alert={alert} message="CPF/Senha inválidos!" />
                  )}

                  <Link to="/recuperar-senha">Esqueceu a senha?</Link>
                  <Button
                    type="submit"
                    disabled={loading}
                    fullWidth
                    size="large"
                    variant="outlined"
                  >
                    {loading && (
                      <CircularProgress
                        size={20}
                        style={{ color: theme.primary }}
                      />
                    )}
                    {!loading && "ENTRAR"}
                  </Button>

                  <a
                    className="developed-by"
                    href="https://www.linkedin.com/in/antonio-alberto-almeida-mendes/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    2021 - {currentYear} Desenvolvido por: Antonio Almeida
                  </a>
                </FooterForm>
              </form>
            </CardContentLogin>
          </CardLogin>
        </WrapperCardLogin>
      </ContainerLogin>
    </>
  );
}
