export default function userLogged(state = {}, action) {
  switch (action.type) {
    case "SET_USER":
      state = action.user;
      return state;

    default:
      return state;
  }
}
