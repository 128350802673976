import React from "react";
import {
  ListSkillOne,
  ListSkillTwo,
  ListSkillThree,
  ListSkillFour,
  ListSkillFive,
} from "./style";

export default function ListFastAnswers({setObservation}) {
  return (
    <>
      <ListSkillOne>
        <h2>Competência 1</h2>
        <ul>
          <li
            onClick={() =>
              setObservation(
                "Falha relacionada à estrutura sintática: período truncado.",
                1
              )
            }
          >
            Falha relacionada à estrutura sintática: período truncado.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Falha relacionada à estrutura sintática: justaposição de palavras ou orações.",
                1
              )
            }
          >
            Falha relacionada à estrutura sintática: justaposição de palavras ou
            orações.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Falha relacionada à estrutura sintática: ausência de termos.",
                1
              )
            }
          >
            Falha relacionada à estrutura sintática: ausência de termos.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Falha relacionada à estrutura sintática: excesso de palavras.",
                1
              )
            }
          >
            Falha relacionada à estrutura sintática: excesso de palavras.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Falha relacionada à estrutura sintática: duplicação de palavras.",
                1
              )
            }
          >
            Falha relacionada à estrutura sintática: duplicação de palavras.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Desvio de convenção da escrita: acentuação.",
                1
              )
            }
          >
            Desvio de convenção da escrita: acentuação.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Desvio de convenção da escrita: ortografia.",
                1
              )
            }
          >
            Desvio de convenção da escrita: ortografia.
          </li>
          <li
            onClick={() =>
              setObservation("Desvio de convenção da escrita: hífen.", 1)
            }
          >
            Desvio de convenção da escrita: hífen.
          </li>

          <li
            onClick={() =>
              setObservation(
                "Desvio de convenção da escrita: maiúscula / minúscula.",
                1
              )
            }
          >
            Desvio de convenção da escrita: maiúscula / minúscula.
          </li>

          <li
            onClick={() =>
              setObservation(
                "Desvio de convenção da escrita: separação silábica.",
                1
              )
            }
          >
            Desvio de convenção da escrita: separação silábica.
          </li>

          <li
            onClick={() =>
              setObservation("Desvio gramatical: regência.", 1)
            }
          >
            Desvio gramatical: regência.
          </li>

          <li
            onClick={() =>
              setObservation("Desvio gramatical: concordância.", 1)
            }
          >
            Desvio gramatical: concordância.
          </li>

          <li
            onClick={() =>
              setObservation("Desvio gramatical: pontuação.", 1)
            }
          >
            Desvio gramatical: pontuação.
          </li>

          <li
            onClick={() => setObservation("Desvio gramatical: crase.", 1)}
          >
            Desvio gramatical: crase.
          </li>

          <li
            onClick={() =>
              setObservation("Desvio gramatical: emprego de pronomes.", 1)
            }
          >
            Desvio gramatical: emprego de pronomes.
          </li>

          <li
            onClick={() =>
              setObservation(
                "Desvio gramatical: paralelismo sintático.",
                1
              )
            }
          >
            Desvio gramatical: paralelismo sintático.
          </li>

          <li
            onClick={() =>
              setObservation(
                "Desvio de escolha de registro: informalidade / marca de oralidade.",
                1
              )
            }
          >
            Desvio de escolha de registro: informalidade / marca de oralidade.
          </li>

          <li
            onClick={() =>
              setObservation(
                "Desvio de escolha vocabular: escolha lexical imprecisa.",
                1
              )
            }
          >
            Desvio de escolha vocabular: escolha lexical imprecisa.
          </li>
        </ul>
      </ListSkillOne>

      <ListSkillTwo>
        <h2>Competência 2</h2>
        <ul>
          <li
            onClick={() => setObservation("Tangenciamento do tema.", 2)}
          >
            Tangenciamento do tema.
          </li>
          <li
            onClick={() =>
              setObservation("Há traços de outros gêneros textuais.", 2)
            }
          >
            Há traços de outros gêneros textuais.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Há presença de cópias dos textos motivadores.",
                2
              )
            }
          >
            Há presença de cópias dos textos motivadores.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Repertório baseado nos textos motivadores.",
                2
              )
            }
          >
            Repertório baseado nos textos motivadores.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Repertório sociocultural pertinente ao tema e sem uso produtivo.",
                2
              )
            }
          >
            Repertório sociocultural pertinente ao tema e sem uso produtivo.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Repertório sociocultural produtivo e pertinente ao tema.",
                2
              )
            }
          >
            Repertório sociocultural produtivo e pertinente ao tema.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Retome algum repertório sociocultural utilizado ao longo do seu texto.",
                2
              )
            }
          >
            Retome algum repertório sociocultural utilizado ao longo do seu
            texto.
          </li>
        </ul>
      </ListSkillTwo>

      <ListSkillThree>
        <h2>Competência 3</h2>
        <ul>
          <li
            onClick={() =>
              setObservation("Projeto de texto com muitas falhas.", 3)
            }
          >
            Projeto de texto com muitas falhas.
          </li>
          <li
            onClick={() =>
              setObservation("Projeto de texto com algumas falhas.", 3)
            }
          >
            Projeto de texto com algumas falhas.
          </li>
          <li
            onClick={() =>
              setObservation("Projeto de texto com poucas falhas.", 3)
            }
          >
            Projeto de texto com poucas falhas.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Desenvolve uma grande parte das informações, fatos e opiniões.",
                3
              )
            }
          >
            Desenvolve uma grande parte das informações, fatos e opiniões.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Desenvolve as informações, fatos e opiniões no texto inteiro.",
                3
              )
            }
          >
            Desenvolve as informações, fatos e opiniões no texto inteiro.
          </li>
        </ul>
      </ListSkillThree>

      <ListSkillFour>
        <h2>Competência 4</h2>
        <ul>
          <li
            onClick={() =>
              setObservation("Presença rara dos elementos coesivos.", 4)
            }
          >
            Presença rara dos elementos coesivos.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Presença pontual dos elementos coesivos.",
                4
              )
            }
          >
            Presença pontual dos elementos coesivos.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Presença regular dos elementos coesivos.",
                4
              )
            }
          >
            Presença regular dos elementos coesivos
          </li>
          <li
            onClick={() =>
              setObservation(
                "Presença constante dos elementos coesivos.",
                4
              )
            }
          >
            Presença constante dos elementos coesivos.
          </li>
          <li
            onClick={() =>
              setObservation(
                "Presença expressiva dos elementos coesivos.",
                4
              )
            }
          >
            Presença expressiva dos elementos coesivos.
          </li>
        </ul>
      </ListSkillFour>

      <ListSkillFive>
        <h2>Competência 5</h2>
        <ul>
          <li
            onClick={() => setObservation("Há presença de: Efeito.", 5)}
          >
            Há presença de: Efeito.
          </li>
          <li
            onClick={() => setObservation("Há presença de: Agente.", 5)}
          >
            Há presença de: Agente.
          </li>
          <li onClick={() => setObservation("Há presença de: Ação.", 5)}>
            Há presença de: Ação.
          </li>
          <li
            onClick={() =>
              setObservation("Há presença de: Detalhamento.", 5)
            }
          >
            Há presença de: Detalhamento.
          </li>
          <li onClick={() => setObservation("Há presença de: Modo.", 5)}>
            Há presença de: Modo.
          </li>
        </ul>
      </ListSkillFive>
    </>
  );
}
