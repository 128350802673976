import React from "react";
import TableForm from "../../components/TableForm";
import Title from "../../components/Title";

export default function Recommendations() {
  const columns = [
    { field: "NAME", name: "NOME DO PARTICIPANTE" },
    { field: "PHONE", name: "TELEFONE DO PARTICIPANTE" },
    { field: "RECOMMENDATION", name: "NOME DA INDICAÇÃO" },
    { field: "PHONE_RECOMMENDATION", name: "TELEFONE DA INDICAÇÃO" },
  ];

  return (
    <>
      <Title name="Indicação premiada" />

      <TableForm url="/indicacao-premiada/listar" columns={columns} />
    </>
  );
}
