import React, { useCallback, useEffect } from "react";
import SelectOption from "../Select";
import InputPassword from "../InputPassword";
import { comparePasswords } from "../../validators/comparePasswords";
import InputPhone from "../InputPhone";
import ButtonSave from "../ButtonSave";
import { FormMyProfileDataContainer } from "./style";
import { Grid, MenuItem } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../store/Loading/actions";
import { toast } from "react-toastify";
import API from "../../utils/api";

export default function FormMyProfileData() {
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state.userLogged);

  const { control, handleSubmit, errors, setValue } = useForm({
    mode: "all",
  });

  const onSubmit = async (data) => {
    try {
      dispatch(showLoading());
      const res = await API.put(
        `/usuarios/alterar-meu-perfil/${userLogged.id_user}`,
        data
      );
      toast.success(`${res.data.message}`);
      setValue("password", "");
      setValue("confirm_password", "");
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  };

  const getUserData = useCallback(async () => {
    try {
      dispatch(showLoading());
      const res = await API.get(`/usuarios/meu-perfil`);

      setValue("cellphone", res.data.CELLPHONE);
      setValue(
        "educational_level",
        res.data.EDUCATIONAL_LEVEL ? res.data.EDUCATIONAL_LEVEL : ""
      );
    } catch (error) {
      toast.error(
        error && error.data && error.data.error
          ? `${error.data.error}`
          : "Ocorreu um erro."
      );
    } finally {
      dispatch(hideLoading());
    }
  }, [dispatch, setValue]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <FormMyProfileDataContainer>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} direction="row" justifyContent="center">
          <Grid item xs={12} sm={userLogged.id_group === 3 ? 3 : 4}>
            <InputPhone
              name="cellphone"
              label="Celular"
              control={control}
              rules={{ required: true }}
              errors={errors}
            />
          </Grid>

          {userLogged.id_group === 3 && (
            <Grid item xs={12} sm={3}>
              <SelectOption
                id="educational_level"
                name="educational_level"
                label="Candidato"
                control={control}
                rules={{ required: true }}
                errors={errors}
              >
                <MenuItem value="Treineiro">Treineiro</MenuItem>
                <MenuItem value="Participante">Participante</MenuItem>
              </SelectOption>
            </Grid>
          )}

          <Grid item xs={12} sm={userLogged.id_group === 3 ? 3 : 4}>
            <InputPassword
              name="password"
              label="Senha"
              control={control}
              errors={errors}
            />
          </Grid>

          <Grid item xs={12} sm={userLogged.id_group === 3 ? 3 : 4}>
            <InputPassword
              name="confirm_password"
              label="Confirmar Senha"
              control={control}
              rules={{
                validate: (value) => comparePasswords(value, control),
              }}
              errors={errors}
            />
          </Grid>
        </Grid>

        <div className="actions">
          <ButtonSave />
        </div>
      </form>
    </FormMyProfileDataContainer>
  );
}
