import styled from "styled-components";

export const CorrectionContainer = styled.div`
  overflow: hidden;
  .MuiAppBar-colorPrimary {
    position: relative;
    background-color: #2f3a8f;
  }

  .MuiToolbar-root {
    display: flex;
    justify-content: space-between;
  }

  .MuiDialogContent-root {
    padding: 0;
  }

  .wrapper-buttons {
    span {
      color: white;
    }
  }

  .wrapper-data-answer {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  textarea {
    width: 100%;
    padding: 18.5px 14px;
    border: 1px solid #c4c4c4;
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    border-radius: 4px;
    resize: none;
    min-height: 136px;
    &:focus {
      outline: auto 1px #3f51b5;
    }
    &:disabled {
      background: white !important;
      color: black !important;
    }
    &.error {
      border: 1px solid red;
    }
  }

  textarea::-webkit-input-placeholder {
    color: #757575;
  }

  textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #757575;
  }

  textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #757575;
  }

  textarea:-ms-input-placeholder {
    color: #757575;
  }

  textarea::placeholder {
    color: #757575;
  }

  @media screen and (max-width: 1023px) {
    overflow: initial;

    .wrapper-data-answer h6 {
      font-size: 14px;
    }
  }
`;

export const ContentModal = styled.div`
  h2 {
    margin-top: 0;
    margin-bottom: 0.8rem;
    font-weight: initial;
  }

  .titleFastAnswers {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    h2 {
      margin-bottom: 0;
      margin-left: 0.8rem;
    }

    svg {
      cursor: pointer;
    }
  }

  .observation {
    > button {
      background: #2f3a8f;
      color: white;
      margin-bottom: 0.8rem;
      &:hover {
        background: #2f3a8f;
        color: white;
      }
    }
    textarea {
      width: 100%;
      padding: 18.5px 14px;
      border: 1px solid #c4c4c4;
      font-size: 16px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
      border-radius: 4px;
      resize: none;
      min-height: 136px;
      &:focus {
        outline: auto 1px #3f51b5;
      }
      &.error {
        border: 1px solid red;
      }
    }

    textarea::-webkit-input-placeholder {
      color: #757575;
    }

    textarea:-moz-placeholder {
      /* Firefox 18- */
      color: #757575;
    }

    textarea::-moz-placeholder {
      /* Firefox 19+ */
      color: #757575;
    }

    textarea:-ms-input-placeholder {
      color: #757575;
    }

    textarea::placeholder {
      color: #757575;
    }

    #audio-list {
      audio {
        margin-top: 1rem;
      }
    }

    .actions {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }

  .wrapper-skills {
    margin-bottom: 2rem;
    display: flex;
    div {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      width: 39px;
      height: 39px;
      font-size: 25px;
      font-weight: bold;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .skill-1 {
      border: 1px solid;
      border-color: #6e2167;
      color: #6e2167;
    }

    .skill-1.active {
      background: #6e2167;
      color: white;
    }

    .skill-2 {
      border: 1px solid;
      border-color: #c02a63;
      color: #c02a63;
    }

    .skill-2.active {
      background: #c02a63;
      color: white;
    }

    .skill-3 {
      border: 1px solid;
      border-color: #01579c;
      color: #01579c;
    }

    .skill-3.active {
      background: #01579c;
      color: white;
    }

    .skill-4 {
      border: 1px solid;
      border-color: #e63405;
      color: #e63405;
    }

    .skill-4.active {
      background: #e63405;
      color: white;
    }

    .skill-5 {
      border: 1px solid;
      border-color: #277663;
      color: #277663;
    }

    .skill-5.active {
      background: #277663;
      color: white;
    }
  }

  #observation-type-form-control {
    .MuiFormLabel-root {
      background-color: white;
      padding-right: 4px;
      padding-left: 4px;
    }
    margin-bottom: 1rem;
  }
`;

export const CorrectionContent = styled.div`
  display: flex;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  height: calc(100vh - 160px);
  overflow-y: auto;
  width: calc(100% - 360px);
  background: white;
  padding: 1rem;

  .wrapper-zoom {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    .rotate {
      button {
        color: #2f3a8f;
        &:hover {
          background: white;
        }
      }
    }

    .zoom {
      width: 500px;
      display: flex;
      color: gray;
      justify-content: center;
      align-items: center;

      .MuiSlider-root {
        color: #2f3a8f;
      }

      svg {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }
  }

  .view-content {
    position: relative;
  }

  img {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }
  #canvas {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .skill-1 {
    background: rgb(110 34 103 / 50%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    @media (min-width: 320px) and (max-width: 600px) {
      font-size: 7px; !important
    }
  }

  .skill-2 {
    background: rgb(192 42 99 / 50%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    @media (min-width: 320px) and (max-width: 600px) {
      font-size: 7px; !important
    }
  }

  .skill-3 {
    background: rgb(1 87 156 / 50%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    @media (min-width: 320px) and (max-width: 600px) {
      font-size: 7px; !important
    }
  }

  .skill-4 {
    background: rgb(230 52 5 / 50%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    @media (min-width: 320px) and (max-width: 600px) {
      font-size: 7px; !important
    }
  }

  .skill-5 {
    background: rgb(39 118 99 / 50%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    @media (min-width: 320px) and (max-width: 600px) {
      font-size: 7px; !important
    }
  }

  @media screen and (max-width: 1023px) {
    height: auto;
    overflow-y: hidden;
    width: 100%;
    .wrapper-zoom {
      display: none;
    }
  }
`;

export const RightSide = styled.div`
  height: calc(100vh - 160px);
  overflow-y: auto;
  width: 360px;
  background: #2f3a8f;
  padding: 1rem;

  .wrapperTotal {
    display: flex;
    justify-content: flex-end;
    .totalGradeBox {
      padding: 0.5rem 1rem;
      font-size: 12px;
      background: white;
      border-radius: 5px;
      margin-bottom: 1rem;
      width: fit-content;
      display: flex;
      flex-direction: column;
    }
  }

  .observation-student {
    h2,
    p {
      color: white;
    }
    p {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 15px;
      svg {
        margin-right: 10px;
      }
    }
  }

  .observation-broker {
    h2 {
      color: white;
    }
  }

  .annotations {
    .wrapper-title-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .visibility {
        color: white;

        .MuiSvgIcon-root {
          margin-right: 0.5rem;
        }
      }
    }
    h2 {
      color: white;
    }
    .MuiSvgIcon-root {
      cursor: pointer;
    }
  }

  .notes-list {
    padding: 1rem;
    background: white;
    border-radius: 5px;
    .notes-item {
      display: flex;
      align-items: center;
      div {
        padding: 0 5px;
        border-radius: 50px;
        margin-right: 7px;
        height: fit-content;
      }
      :not(:last-child) {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ddd;
      }

      audio {
        margin-right: 7px;
      }

      .actions {
        padding: 0;
        > span {
          margin-right: 2px;
        }
        &:hover {
          background: none !important;
        }
        svg {
          width: 0.8em;
          height: 0.8em;
        }
      }

      .observation {
        width: 100%;
        margin-right: 7px;
      }

      .skill-1 {
        background: #6e2167;
        color: white;
        text-align: center;
      }

      .skill-2 {
        background: #c02a63;
        color: white;
        text-align: center;
      }

      .skill-3 {
        background: #01579c;
        color: white;
        text-align: center;
      }

      .skill-4 {
        background: #e63405;
        color: white;
        text-align: center;
      }

      .skill-5 {
        background: #277663;
        color: white;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    height: auto;
    width: 100%;
  }
`;
