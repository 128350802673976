import React from "react";
import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { NotificationContainer } from "./style";

export default function ContentNotificationStudentsModal({ data, closeModal }) {
  return (
    <NotificationContainer>
      <DialogTitle>
        Status das notificações:
        <IconButton component="span" onClick={closeModal}>
          <Tooltip title="Fechar">
            <Cancel />
          </Tooltip>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <ul>
            {data?.success.map((student) => (
              <li className="success">
                {student.NAME} - {student.CELLPHONE}
              </li>
            ))}
            {data?.failed.map((student) => (
              <li className="error">
                {student.NAME} - {student.CELLPHONE}
              </li>
            ))}
          </ul>
        </DialogContentText>
      </DialogContent>
    </NotificationContainer>
  );
}
