export function showLoading() {
  return {
    type: "SHOW_LOADING",
    loading: true,
  };
}

export function hideLoading() {
  return {
    type: "HIDE_LOADING",
    loading: false,
  };
}
