import styled from "styled-components";

export const WrapperCancel = styled.div`
  button {
    color: #fff;
    min-width: 120px;
    background-color: red;
  }
  button:hover {
    color: #fff;
    background-color: red;
  }
`;
